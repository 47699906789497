@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
body {
  margin: 0;
  font-family: 'Press Start 2P';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: url(/static/media/synthwave.ee94e2d8.jpg) no-repeat center center fixed;
  background-size: cover;
}
a:hover {
  color: #b03013;
}
a {
  color: #c0392b;
  display: inline-block;
  line-height: 2em;
  transition: transform 0.1s linear;
}
.Nav {
  background-color: #2f2c47;
  min-height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  color: #f5f4f4;
}
.Footer {
  background-color: #2f2c47;
  min-height: 5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: #f5f4f4;
}
.LeaderBoard {
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f4f4;
}
.GroupPage {
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f4f4;
  line-height: 2em;
  color: #2f2c47;
}
.loading {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  background-color: #f5f4f4;
  flex-direction: column;
}
.groupImage {
  min-width: 75%;
  max-width: 80%;
  margin: 20px auto;
}
.groupName {
  margin: 1em;
}
.navBar {
  text-align: center;
  display: flex;
  align-items: center;
  height: 3em;
  background-color: #2f2c47;
  color: #f5f4f4;
  justify-content: space-around;
  list-style-type: none;
}
.sortBar {
  width: 100%;
  margin: 0;
  margin-bottom: 2px;
  padding: 0;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  list-style-type: none;
}
.sortElement {
  background-color: #f5f4f4;
  text-align: center;
  width: 33%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #2f2c47;
  text-decoration: none;
}

.sortElementSelected,
.sortElement:hover {
  background-color: #c8786f;
  color: white;
}
.leaderboardTitle {
  text-decoration: none;
  text-align: center;
  color: white;
}
.listElement {
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  font-size: 18px;
  text-decoration: none;
  line-height: 1.2em;
}
.listElement:hover {
  background-color: #c8786f;
}
.listElementDark {
  background-color: lightgrey;
}
.boardRank {
  margin-left: 1em;
  color: #2f2c47;
}
.boardName {
  width: 25em;
  color: #2f2c47;
}
.boardScore {
  width: 2em;
  margin-right: 3em;
  color: #2f2c47;
  text-align: center;
}
.container {
  width: 700px;
  margin: 80px auto;
}
@media (max-width: 750px) {
  .container {
    width: 100%;
    margin: 0 0;
  }
  .leaderboardTitle {
    font-size: 16px;
  }
  .listElement {
    font-size: 14px;
  }
  .Nav {
    font-size: 20px;
  }
  .sortBar {
    font-size: 14px;
  }
  .GroupPage {
    font-size: 14px;
  }
}

